import { Box, Container, Flex, Grid, Text, Image } from "@theme-ui/components"
import React from "react"
import { useCompany } from "../hooks/useCompany"
// import { useFooter } from "../hooks/useFooter"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from "react-feather"
import LogoFooter from "../images/logo-footer.svg"
import LogoMulti from "../images/logo-multi.svg"
import LanguageSwitcher from "./languageSwitcher"

const Footer = () => {
  const footer = [] //useFooter()
  const company = useCompany()
  const social = useSocial()

  footer.map(footerItem => {
    footerItem.treeChildren.sort((a, b) => a.position - b.position)
    footerItem.treeChildren.map(footerItem => {
      if (footerItem.treeChildren.length > 0) {
        footerItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box as="footer" sx={{ backgroundColor: "primary", py: 5, color: "dark" }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container variant="custom" sx={{ borderTop:"1px solid", borderColor:"dark", pt:[3,4], pb:[4,5]}}>
              <Grid
                columns={[1, "1fr 1fr"]}
                sx={{ justifyContent: "space-between" }}
              >
                {console.log(company)}
                <Box
                  as="div"
                  sx={{
                    p: {
                      fontWeight: "600",
                      a: {
                        color: "dark",
                      },
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: company.body }}
                ></Box>
                <Box>
                  <Flex sx={{alignContent:"center", justifyContent:["space-between","end"], alignItems:"end"}}>
                    <Image src={LogoFooter} sx={{ mr:[5]}}/>
                    <Image src={LogoMulti} />
                  </Flex>
                  <Box sx={{m:[4]}}>
                    <LanguageSwitcher />
                  </Box>
                </Box>
              </Grid>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 4,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 2, "&:last-child": { mb: 0 } }} />
}

export default Footer
