import React, { useState } from "react"
import { Box, Flex } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { InboundLink } from "./link"

const LanguageSwitcher = () => {
  const [show, setShow] = useState(false)

  const languagesCountry = {
    it: "Italiano",
    en: "English",
    "en-us": "English (United States)",
    "en-ca": "English (Canada)",
    fr: "Français",
    de: "Deutsch",
    es: "Español",
    pt: "Português",
    pl: "Polski",
    ru: "Русский",
  }

  

  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        const activeLink = paths.find(x => x.locale === activeLocale)
        return (
          <Box
            sx={{ position: "relative", textAlign:"right" }}
            onMouseEnter={() => setShow(!show)}
            onMouseLeave={() => setShow(!show)}
          >
            <Flex sx={{justifyContent:"flex-end"}}>
              {
                paths
                  .map((link, index) => (
                    <Box key={index}>
                      <InboundLink
                        to={link.value}
                        sx={{
                          whiteSpace: "nowrap",
                          padding: 2,
                          fontSize:[3],
                          display: "block",
                          borderRadius: "xs",
                          color:
                            activeLocale === link.locale ? "light" : "dark",
                          textDecoration:"none"
                        }}
                      >
                        { link.locale || languagesCountry[link.locale]}
                      </InboundLink>
                    </Box>
                  ))
              }
            </Flex>
          </Box>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcher
